(function () {
  'use strict';

  /* @ngdoc object
   * @name login
   * @description
   *
   */
  angular
    .module('login', [
      'ui.router',
      'login.login',
      'login.forgotten',
      'login.reset'
    ]);
}());
